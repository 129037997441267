.drawing-container {
    background-color: #4fcf70;
    color: white;
    height: calc(100vh - 70px);
}

.drawing,
.drawingX,
.drawingY {
    padding: 30px;
    height: inherit;
    background-color: black;
    clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 100% 100%, 0 100%, 0% 60%, 0 0);
    color: white;
    margin: 0 auto;
}

.drawingY {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.drawingX pre {
    margin-top: 15px;
}

.drawingX .ascii-line,
.drawingY .ascii-line {
    background: linear-gradient(115deg, #4fcf70, #fad648, #a767e5, #12bcfe, #44ce7b);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0px 0px 10px teal;
}

.drawingX .ascii-line {
    animation: animatedLineX .5s;
}

.drawingY .ascii-line {
    animation: animatedLineY .5s;
}

@keyframes animatedLineX {
    from {
        transform: translateX(-150px);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes animatedLineY {
    from {
        transform: translateY(150px);
    }

    to {
        transform: translateY(0);
    }
}