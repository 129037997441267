.nav {
    z-index: 1;
    background-color: black;
    height: 70px;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 35px;
    align-items: center;
    font-weight: 600;
    letter-spacing: 2px;
    border-bottom: 4px solid #4fcf70;
    position: sticky;
    top: 0;
    transition: all .3s;
}

.nav .nav-items {
    display: flex;
    grid-gap: 30px;
}

.nav-item:hover:not(.active) {
    cursor: pointer;
    opacity: .7;
}