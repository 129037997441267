.ascii-name {
    display: grid;
    justify-content: center;
    align-items: center;
    font-size: .6rem;
}

.ascii-computer {
    display: grid;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
}