.prompt {
    padding: 20px;
    min-height: calc(100vh - 70px);
    background-color: #211D1B;
    color: white;
    font-family: monospace;
    scroll-behavior: smooth;
    padding-bottom: 200px;
}

.prompt-text {
    position: fixed;
    right: 0;
    color: black;
    left: -1000px;
    bottom: -1000px;
}

.prompt h1 {
    display: none;
}

.prompt i {
    margin-right: 10px;
    color: #73ABAD !important;
}

.prompt pre,
.prompt p,
.prompt .navigate a span {
    margin: 5px 0 !important;
    color: #B89076 !important;
}

.prompt-comment-area {
    display: flex;
    color: #519975 !important;
}

.prompt-comment-area::before {
    margin-right: 5px;
    color: #519975;
    content: "visitor@firat:~$";
}

.prompt-typer {
    width: 15px;
    display: block;
    height: 22px !important;
    background-color: #73ABAD;
    animation: animateTyper .5s linear .5s infinite alternate;
}

.prompt-comment {
    color: #73ABAD;
}

@keyframes animateTyper {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}