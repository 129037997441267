* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    color: white;
}

body {
    background-color: black;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.active {
    cursor: default;
    color: #4fcf70;
}

.spacing-none {
    letter-spacing: -5px;
}

.centered-flex {
    display: flex;
    align-items: center;
}

.bi-copy,
.bi-linkedin,
.bi-github {
    margin-right: 10px;
    cursor: pointer;
}

.navigate {
    margin-top: 15px !important;
}

.text-teal {
    color: #73ABAD;
    text-shadow: 0 0 5px #73ABAD;
}

.text-not {
    color: #9C8394;
}