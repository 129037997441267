.projects {
    display: grid;
    grid-gap: 20px;
    padding: 20px;
    margin: 50px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.project {
    text-align: center;
    display: grid;
    grid-template-rows: 1fr 3fr;
    align-items: center;
    justify-content: center;
    border: 5px solid black;
    transition: all .3s;
    background-color: #101217;
    box-shadow: 0 0 1px #4fcf70;
    position: relative;
    outline: 1px solid transparent;
}

.project:hover {
    outline: 1px solid #4fcf70;
}

.project-status {
    font-size: 1.2rem;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #4fcf70;
    padding: 5px 10px;
}

.project-title {
    padding: 20px;
    color: #4fcf70;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0px 0px 1px #4fcf70;
    background-color: #101217;
}

.project-description {
    font-style: italic;
    color: #5d5d5d;
    font-size: 15px;
    padding: 20px;
}